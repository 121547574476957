import { ButtonPrimary } from "../../shared/component/button/ButtonPrimary";
import { ButtonPurple } from "../../shared/component/button/ButtonPurple";
import { ButtonSubmit } from "../../shared/component/button/ButtonSubmit";
import { InputForm } from "../../shared/component/inputForm/InputForm";
import { Loading } from "../../shared/component/loading/Loading";
import { ModalDialog } from "../../shared/component/modal/ModalDialog";
import { Search } from "../../shared/component/search/Search";
import { Sidebar } from "../../shared/component/sidebar/Sidebar";
import UseProductMerchant from "./UseProductMerchant";
import NoData from "../../assets/image/nodata.svg";
import MerchantError from "../../assets/image/merchanterror.svg";
import RemarkNewLogo from "../../assets/image/rem_new.svg";
import RemarkTrendingLogo from "../../assets/image/rem_trending.svg";
import RemarkPromoLogo from "../../assets/image/rem_promo.svg";
import RemarkDefaultLogo from "../../assets/image/rem_default.svg";
import Select from "react-select";
export const ProductMerchant = () => {
  const {
    isLoading,
    isAdminNarindo,
    productExport,
    onSearchProduct,
    search,
    handleSearchChange,
    onSaveChangesModal,
    editProduct,
    onChangeCheckBox,
    onChangeEdit,
    handleEditShow,
    handleEditClose,
    viewEdit,
    merchant,
    prepaidCategory,
    postpaidCategory,
    data,
    onChangeTable,
    onChangeEditMargin,
    onChangeEditMarginAgent,
    allItems,
    onChangeSetMerchant,
    merchantCode,
    thousands_separators,
    error,
    onChangeFilterStatus,
    noData,
    merchantList,
    insertExport,
    exportItems,
  } = UseProductMerchant();

  let branch1Prepaid = [];
  let branch2Prepaid = [];
  let branch1Postpaid = [];
  let branch2Postpaid = [];

  const loopBranch1 = (category, parent1) => {
    if (category === "Prepaid") {
      if (data[`${category}`] != undefined) {
        branch1Prepaid = Object.keys(data[`${category}`][`${parent1}`]);
      } else {
        branch1Prepaid = [];
      }
      return;
    } else {
      if (data[`${category}`] != undefined) {
        branch1Postpaid = Object.keys(data[`${category}`][`${parent1}`]);
      } else {
        branch1Postpaid = [];
      }
    }
  };

  const loopBranch2 = (category, parent2, parent3) => {
    if (category === "Prepaid") {
      branch2Prepaid = Object.values(
        data[`${category}`][`${parent2}`][`${parent3}`]
      );
      for (let i in branch2Prepaid) {
        allItems.push(branch2Prepaid[i]);
        insertExport(branch2Prepaid[i]);
      }
    } else {
      branch2Postpaid = Object.values(
        data[`${category}`][`${parent2}`][`${parent3}`]
      );
      for (let i in branch2Postpaid) {
        allItems.push(branch2Postpaid[i]);
        insertExport(branch2Postpaid[i]);
      }
    }
  };
  return (
    <>
      <Sidebar title="Produk">
        <div className="flex items-center ml-3 flex-row  pl-0">
          {isAdminNarindo && (
            <>
              <div className="w-28">
                <label className="block text-[16px] text-gray-dark font-poppins">
                  Merchant
                </label>
              </div>
              <div className="w-1/5 text-[14px] relative mr-4">
                <Select
                  options={merchantList}
                  classNames={{
                    control: (state) =>
                      state.isFocused ? "border-red-600" : "border-grey-300",
                  }}
                  onChange={(merchant) => onChangeSetMerchant(merchant.value)}
                />
              </div>
            </>
          )}
          <div className="w-28">
            <label className="block text-[16px] text-gray-dark font-poppins">
              Filter Status
            </label>
          </div>
          <div className="w-32 text-[14px] relative">
            <select
              className="form-select appearance-none
                        block w-100  px-3 py-1.5 font-poppins text-gray-text bg-white bg-no-repeat
                        border-2 border-border-input rounded transition ease-in-out m-0
                        focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
              style={{ fontSize: "14px" }}
              onChange={onChangeFilterStatus}
            >
              <option className="text-[14px]" value="ALL">
                All
              </option>
              <option className="text-[14px]" value="1">
                Active
              </option>
              <option className="text-[14px]" value="0">
                Inactive
              </option>
            </select>
          </div>
        </div>
        {merchantCode != "" && error === false && (
          <div className="flex flex-row justify-between items-center mb-3">
            <div className="w-1/4 mt-3">
              <Search
                onChange={handleSearchChange}
                value={search}
                onClick={onSearchProduct}
              />
            </div>
            <ButtonPurple data={exportItems} filename="product_merchant.csv">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <span className="ml-2">Export</span>
            </ButtonPurple>
          </div>
        )}
        {merchantCode !== "" &&
        isLoading === false &&
        error === false &&
        noData == false ? (
          <div>
            <div className="flex flex-row items-start w-full h-1/8 my-3">
              <div className="w-1/3 flex justify-start items-center">
                <h3 className="text-[24px] font-poppins">Prepaid</h3>
              </div>
              <div className="w-2/3 flex justify-end items-center">
                <ButtonPrimary width="240px" onClick={handleEditShow}>
                  Edit Product Merchant
                </ButtonPrimary>
              </div>
            </div>
            <div className="accordion" id="accordionExample1">
              {prepaidCategory.length === 0 ? (
                <></>
              ) : (
                prepaidCategory.map((parent1, index) => {
                  return (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`headingOne${index + 1}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${index + 1}`}
                          aria-expanded="false"
                          aria-controls={`collapseOne${index + 1}`}
                        >
                          {parent1}
                        </button>
                      </h2>
                      {loopBranch1("Prepaid", parent1)}
                      <div
                        id={`collapseOne${index + 1}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`headingOne${index + 1}`}
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body">
                          {branch1Prepaid.length === 0 ? (
                            <></>
                          ) : (
                            branch1Prepaid.map((parent2, i) => {
                              return (
                                <div key={i} className="accordion-item">
                                  <h2
                                    className="accordion-header"
                                    id={`headingTwo${index + 1}${i}`}
                                  >
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapseTwo${
                                        index + 1
                                      }${i}`}
                                      aria-expanded="false"
                                      aria-controls={`collapseTwo${
                                        index + 1
                                      }${i}`}
                                    >
                                      {parent2}
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapseTwo${index + 1}${i}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`headingTwo${
                                      index + 1
                                    }${i}`}
                                  >
                                    <div className="accordion-body">
                                      {loopBranch2("Prepaid", parent1, parent2)}
                                      <table className="table table-bordered table-responsive">
                                        <thead className="bg-gray-dark text-white font-poppins text-center">
                                          <tr className="align-middle select-none">
                                            <th>
                                              <div className="flex items-center justify-center">
                                                <input
                                                  className="w-3 h-3"
                                                  type="checkbox"
                                                  data-testid="test-checkbox-prepaid"
                                                  onChange={(e) =>
                                                    onChangeTable(
                                                      e,
                                                      "Prepaid",
                                                      parent1,
                                                      parent2
                                                    )
                                                  }
                                                  name={`checkboxPrepaid${i}${index}`}
                                                ></input>
                                              </div>
                                            </th>
                                            {/* <th>Active</th>
                                            <th>Product Code</th>
                                            <th>Product Name</th>
                                            <th>Narindo Price</th>
                                            <th>Fee</th>
                                            <th>Margin Type</th>
                                            <th>Margin Agent</th>
                                            <th>Margin Customer</th>
                                            <th>Platform Fee</th>
                                            <th>Customer Price</th> */}
                                            <th>Status</th>
                                            <th>Kode</th>
                                            <th>Deskripsi</th>
                                            <th>Harga Pokok</th>
                                            <th>Margin Type</th>
                                            <th>Margin Pokok</th>
                                            <th>Harga Agen</th>
                                            <th>Margin Agen</th>
                                            <th>Platform Fee</th>
                                            <th>Harga Pelanggan</th>
                                          </tr>
                                        </thead>
                                        <tbody className="font-poppins bg-white text-center">
                                          {branch2Prepaid.length === 0 ? (
                                            <tr>
                                              <th colSpan="33">
                                                Data tidak ada
                                              </th>
                                            </tr>
                                          ) : (
                                            branch2Prepaid.map((parent3, j) => {
                                              return (
                                                <tr
                                                  key={j}
                                                  className=""
                                                  title=""
                                                >
                                                  <td>
                                                    <input
                                                      className="w-3 h-3"
                                                      type="checkbox"
                                                      name={`checkboxPrepaid${i}${index}`}
                                                      onChange={(e) =>
                                                        onChangeCheckBox(
                                                          e,
                                                          parent3.product_code,
                                                          parent3.product_type,
                                                          parent3.product_category,
                                                          parent3.operator_name
                                                        )
                                                      }
                                                    ></input>
                                                  </td>
                                                  <td>
                                                    {parent3.ms_is_active ==
                                                    1 ? (
                                                      <div className="bg-emerald-500 h-4 px-2 text-xs rounded-full text-white flex items-center justify-center">
                                                        Active
                                                      </div>
                                                    ) : (
                                                      <div className="bg-rose-500 h-4 px-2 text-xs rounded-full text-white flex items-center justify-center">
                                                        Inactive
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {parent3.product_code}
                                                  </td>
                                                  <td  className="text-left"  style={{ display: 'flex', alignItems: 'center' }}>
                                                    {parent3.product_name}
                                                    {parent3.remark !== null && parent3.remark !== '0' && parent3.remark !=='' && (
                                                    <img 
                                                      
                                                      style={{ height: '20px', marginLeft: '5px' }}
                                                      src={
                                                        parent3.remark === 'Promo'
                                                          ? RemarkPromoLogo
                                                          : parent3.remark === 'Trending'
                                                          ? RemarkTrendingLogo
                                                          : parent3.remark === 'New'
                                                          ? RemarkNewLogo
                                                          : null // Jika remark tidak cocok dengan kondisi apapun, atur src menjadi null
                                                      }
                                                      title={parent3.remark}
                                                    />
                                                  )}
                                                  </td>
                                                  <td className="text-right">
                                                    {thousands_separators(
                                                      parent3.narindo_price
                                                    )}
                                                  </td>
                                                  {/* <td className="text-right">
                                                    {thousands_separators(
                                                      parent3.fee
                                                    )}
                                                  </td> */}
                                                  <td>
                                                    {parent3.ms_margin_type ===
                                                    "0"
                                                      ? "Nominal"
                                                      : "Percentage"}
                                                  </td>
                                                  <td className="text-right">
                                                    {parent3.ms_margin_type ===
                                                    "0"
                                                      ? thousands_separators(
                                                          parent3.ms_margin
                                                        )
                                                      : parent3.ms_margin + "%"}
                                                  </td>
                                                  <td className="text-right">
                                                    {parent3.ms_margin_type ===
                                                    "0"
                                                      ? thousands_separators(
                                                          parent3.narindo_price +
                                                            parent3.ms_margin
                                                        )
                                                      : thousands_separators(
                                                          parent3.narindo_price +
                                                            parent3.narindo_price *
                                                              (parent3.ms_margin /
                                                                100)
                                                        )}
                                                  </td>
                                                  <td className="text-right">
                                                    {parent3.ms_margin_type ===
                                                    "0"
                                                      ? thousands_separators(
                                                          parent3.ms_margin_agent
                                                        )
                                                      : parent3.ms_margin_agent +
                                                        "%"}
                                                  </td>
                                                  <td className="text-right">
                                                    {thousands_separators(
                                                      parent3.mdr
                                                    )}
                                                  </td>
                                                  <td className="text-right">
                                                    {thousands_separators(
                                                      parent3.ms_sell_price_merchant
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <h3 className="text-[24px] font-poppins my-3">Postpaid</h3>
            <div className="accordion" id="accordionExample2">
              {postpaidCategory.length === 0 ? (
                <></>
              ) : (
                postpaidCategory.map((parent1, index) => {
                  return (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`headingPostpaid${index}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapsePostpaid${index}`}
                          aria-expanded="false"
                          aria-controls={`collapsePostpaid${index}`}
                        >
                          {parent1}
                        </button>
                      </h2>
                      {loopBranch1("Postpaid", parent1)}
                      <div
                        id={`collapsePostpaid${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`headingPostpaid${index}`}
                        data-bs-parent="#accordionExample2"
                      >
                        <div className="accordion-body">
                          {branch1Postpaid.length === 0 ? (
                            <></>
                          ) : (
                            branch1Postpaid.map((parent2, i) => {
                              return (
                                <div className="accordion-item" key={i}>
                                  <h2
                                    className="accordion-header"
                                    id={`headingPostpaidBranch${index}${i}`}
                                  >
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapsePostpaidBranch${index}${i}`}
                                      aria-expanded="false"
                                      aria-controls={`collapsePostpaidBranch${index}${i}`}
                                    >
                                      {parent2}
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapsePostpaidBranch${index}${i}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`headingPostpaidBranch${index}${i}`}
                                  >
                                    <div className="accordion-body">
                                      {loopBranch2(
                                        "Postpaid",
                                        parent1,
                                        parent2
                                      )}
                                      <table className="table table-bordered table-responsive">
                                        <thead className="bg-gray-dark text-white font-poppins text-center">
                                          <tr className="align-middle select-none">
                                            <th>
                                              <div className="flex items-center justify-center">
                                                <input
                                                  className="w-3 h-3"
                                                  type="checkbox"
                                                  onChange={(e) =>
                                                    onChangeTable(
                                                      e,
                                                      "Postpaid",
                                                      parent1,
                                                      parent2
                                                    )
                                                  }
                                                  name={`checkboxPostpaid${i}${index}`}
                                                ></input>
                                              </div>
                                            </th>
                                            {/* <th>Active</th>
                                            <th>Product Code</th>
                                            <th>Product Name</th>
                                            <th>Sell Price</th>
                                            <th>Narindo Fee</th>
                                            <th>Margin Type</th>
                                            <th>Margin Agent</th>
                                            <th>Margin Customer</th>
                                            <th>Platform Fee</th>
                                            <th>Customer Price</th> */}

                                            <th>Status</th>
                                            <th>Kode</th>
                                            <th>Deskripsi</th>
                                            {/* <th>Sell Price</th> */}
                                            <th>Fee Pokok</th>
                                            <th>Margin Type</th>
                                            <th>Margin Pokok</th>
                                            <th>Fee Agen</th>
                                            <th>Margin Agen</th>
                                            <th>Platform Fee</th>
                                            <th>Fee Pelanggan</th>
                                          </tr>
                                        </thead>
                                        <tbody className="font-poppins bg-white text-center">
                                          {branch2Postpaid.length === 0 ? (
                                            <tr>
                                              <th colSpan="33">
                                                Data tidak ada
                                              </th>
                                            </tr>
                                          ) : (
                                            branch2Postpaid.map(
                                              (parent3, j) => {
                                                return (
                                                  <tr key={j}>
                                                    <td>
                                                      <input
                                                        className="w-3 h-3"
                                                        type="checkbox"
                                                        name={`checkboxPostpaid${i}${index}`}
                                                        data-testid="test-checkbox-postpaid"
                                                        onChange={(e) =>
                                                          onChangeCheckBox(
                                                            e,
                                                            parent3.product_code,
                                                            parent3.product_type,
                                                            parent3.product_category,
                                                            parent3.operator_name
                                                          )
                                                        }
                                                      ></input>
                                                    </td>
                                                    <td>
                                                      {parent3.ms_is_active ==
                                                      1 ? (
                                                        <div className="bg-emerald-500 h-4 px-2 text-xs rounded-full text-white flex items-center justify-center">
                                                          Active
                                                        </div>
                                                      ) : (
                                                        <div className="bg-rose-500 h-4 px-2 text-xs rounded-full text-white flex items-center justify-center">
                                                          Inactive
                                                        </div>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {parent3.product_code}
                                                    </td>
                                                    <td className="text-left">
                                                      {parent3.product_name}</td>
                                                    <td className="text-right">
                                                      {thousands_separators(
                                                        parent3.narindo_price
                                                      )}
                                                    </td>
                                                    {/* <td className="text-right">
                                                      {thousands_separators(
                                                        parent3.fee
                                                      )}
                                                    </td> */}
                                                    <td>
                                                      {parent3.ms_margin_type ===
                                                      "0"
                                                        ? "Nominal"
                                                        : "Percentage"}
                                                    </td>
                                                    <td className="text-right">
                                                      {parent3.ms_margin_type ===
                                                      "0"
                                                        ? thousands_separators(
                                                            parent3.ms_margin
                                                          )
                                                        : parent3.ms_margin +
                                                          "%"}
                                                    </td>
                                                    <td className="text-right">
                                                      {parent3.ms_margin_type ===
                                                      "0"
                                                        ? thousands_separators(
                                                            parent3.narindo_price +
                                                              parent3.ms_margin
                                                          )
                                                        : thousands_separators(
                                                            parent3.narindo_price +
                                                              parent3.narindo_price *
                                                                (parent3.ms_margin /
                                                                  100)
                                                          )}
                                                    </td>
                                                    <td className="text-right">
                                                      {parent3.ms_margin_type ===
                                                      "0"
                                                        ? thousands_separators(
                                                            parent3.ms_margin_agent
                                                          )
                                                        : parent3.ms_margin_agent +
                                                          "%"}
                                                    </td>
                                                    <td className="text-right">
                                                      {thousands_separators(
                                                        parent3.mdr
                                                      )}
                                                    </td>
                                                    <td className="text-right">
                                                      {thousands_separators(
                                                        parent3.ms_sell_price_merchant
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        ) : (
          (merchantCode == "" || noData == true) && (
            <div className="flex items-center justify-center mt-16 xl:mt-44">
              <img
                className=" w-64 h-64 xl:w-96 xl:h-96 "
                src={NoData}
                alt="No data"
              ></img>
            </div>
          )
        )}
        {error === true && (
          <div className="flex items-center justify-center mt-16 xl:mt-44">
            <img
              className=" w-64 h-64 xl:w-96 xl:h-96"
              src={MerchantError}
              alt="No data"
            ></img>
          </div>
        )}
        {viewEdit && (
          <ModalDialog
            isOpen={viewEdit}
            handleClose={handleEditClose}
            onClick={onSaveChangesModal}
            title="Edit Data Produk Merchant"
            label="Simpan"
          >
            <div className="flex flex-col justify-center items-start">
              <div className="md:flex items-center flex flex-row mb-3">
                <div className="w-40">
                  <label
                    id="role"
                    className="text-[14px] block text-gray-dark font-poppins mb-1 md:mb-0 pr-4"
                  >
                    Margin Type
                  </label>
                </div>
                <div className="md:w-40 relative">
                  <select
                    className="form-select appearance-none
                                        block px-3 py-1.5 text-[14px] font-poppins text-gray-text bg-white bg-no-repeat
                                        border-2 border-border-input rounded transition ease-in-out m-0
                                        focus:text-gray-text focus:bg-white focus:border-blue focus:outline-none"
                    style={{ width: "282px", fontSize: "14px" }}
                    id="ms_margin_type"
                    name="ms_margin_type"
                    onChange={onChangeEdit}
                    required
                  >
                    <option className="text-[14px]" id="role" value={"0"}>
                      Nominal
                    </option>
                    <option className="text-[14px]" id="role" value={"1"}>
                      Percentage
                    </option>
                  </select>
                </div>
              </div>
              <InputForm
                id="ms_margin"
                onChange={onChangeEditMarginAgent}
                required
                label="Margin Pokok"
                value={editProduct.ms_margin}
                placeholder="Ex: Nominal : 500, Percentage : 5"
              />
              <InputForm
                id="ms_margin_agent"
                onChange={onChangeEditMargin}
                required
                label="Margin Agen"
                value={editProduct.ms_margin_agent}
                placeholder="Ex: Nominal : 500, Percentage : 5"
              />

            {/* LABEL: Set current product to ~~Promo/New/Trending~~ */}
            <div className="flex flex-row gap-3">
                <label
                  id="product"
                  className="block text-[14px] text-gray-dark font-poppins mb-1 mr-4 self-start md:mb-0 pr-4"
                  style={{paddingTop:'10px'}}
                >
                  Label Produk
                </label>
                <div className="form-check">
                <img
                    className=" w-4 h-4 xl:w-5 xl:h-5"
                    src={RemarkDefaultLogo}
                    alt="No data"
                  ></img>
                  <input
                    value={"0"}
                    onChange={onChangeEdit}
                    className="form-check-input"
                    type="radio"
                    name="remark"
                    id="flexRadioDefaultLabel4"
                    defaultChecked
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexRadioDefaultLabel4"
                  >Default
                  </label>
                </div>
                <div className="form-check" style={{marginLeft:'30px'}}>
                <img
                    className=" w-4 h-4 xl:w-5 xl:h-5"
                    src={RemarkNewLogo}
                    alt="No data"
                  ></img> 
                  <input
                    value={"New"}
                    onChange={onChangeEdit}
                    className="form-check-input"
                    type="radio"
                    name="remark"
                    id="flexRadioDefaultLabel2"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexRadioDefaultLabel2"
                  >New
                  </label>
                </div>
                <div className="form-check" style={{marginLeft:'30px'}}>
                <img
                    className=" w-4 h-4 xl:w-5 xl:h-5"
                    src={RemarkTrendingLogo}
                    alt="No data"
                  ></img>
                  <input
                    value={"Trending"}
                    onChange={onChangeEdit}
                    className="form-check-input"
                    type="radio"
                    name="remark"
                    id="flexRadioDefaultLabel3"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexRadioDefaultLabel3"
                  >Trending
                  </label>
                </div>
                <div className="form-check" style={{marginLeft:'30px'}}>
                <img
                    className=" w-4 h-4 xl:w-5 xl:h-5"
                    src={RemarkPromoLogo}
                    alt="No data"
                  ></img>
                  <input
                    value={"Promo"}
                    onChange={onChangeEdit}
                    className="form-check-input"
                    type="radio"
                    name="remark"
                    id="flexRadioDefaultLabel1"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexRadioDefaultLabel1"
                  >Promo
                  </label>
                </div>
              </div>

              <div className="flex flex-row gap-3"  style={{marginTop:'25px'}}>
                <label
                  id="product"
                  className="block text-[14px] text-gray-dark font-poppins mb-1 mr-4 self-start md:mb-0 pr-4"
                >
                  Status Produk
                </label>
                <div className="form-check">
                  <input
                    value={1}
                    onChange={onChangeEdit}
                    className="form-check-input"
                    type="radio"
                    name="ms_is_active"
                    id="flexRadioDefault1"
                    defaultChecked
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexRadioDefault1"
                  >
                    Active
                  </label>
                </div>
                <div className="form-check" style={{marginLeft:'30px'}}>
                  <input
                    value={0}
                    onChange={onChangeEdit}
                    className="form-check-input"
                    type="radio"
                    name="ms_is_active"
                    id="flexRadioDefault2"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexRadioDefault2"
                  >
                    Inactive
                  </label>
                </div>
              </div>



            </div>
          </ModalDialog>
        )}
        {isLoading && <Loading></Loading>}
      </Sidebar>
    </>
  );
};
